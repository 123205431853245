import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';
import './styles/index.scss';
import { Container } from "react-bootstrap";
import { StructuredText } from "react-datocms";

const CookiesPolicy = ({ data }) => {
  return (
    <div className="background-theme">
      <Container className="pt-5 pb-5 internal-pages-common technology">
        <h1>{data.allDatoCmsCookiesPage.nodes[0].cookiesTitle}</h1>
        <StructuredText data={data.allDatoCmsCookiesPage.nodes[0].cookiesDescription.value} />
      </Container>
    </div>
  )
}

export default CookiesPolicy

// export const Head = () => <Seo title="Cookies Policy" />

export const Head = ({ data }) => (
  <Seo
    title="Cookies Policy"
    description="Collect • Play • Trade"
    image="https://www.datocms-assets.com/80384/1664752762-dtc-logo-2021.png?auto=format"
  />
)


export const query = graphql`
query CookiesPolicy {
    allDatoCmsCookiesPage {
      nodes {
        seoMetaTags {
          tags
        }
        id
        cookiesTitle
        cookiesDescription {
          value
        }
      }
    }
  }
`